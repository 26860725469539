@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Lato', 'sans-serif';
  background-color: #1A202C;
  color: #F7FAFC;
}

code {
  font-family: 'Lato', 'sans-serif';
}

.login-container {
  animation: fadeIn 1s ease-in;
}

.login-container.fade-out {
  animation: fadeOut 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10%);
  }
}